import React, { useState } from "react";
import { home } from "./projects";
import styled from "styled-components";
import Text from "./Ui/Text";
import Flex from "./Ui/Flex";
import Logo from "./Logo";
import Media from "./Styles/Media";

const Loader = ({ setUploaded }) => {
  const images =
    window.innerWidth > 768
      ? home
          .filter((p) => p.url.indexOf("mp4") < 0)
          .reduce((acc, p) => [...acc, p.url], [])
      : home
          .filter((p) => p.url.indexOf("mp4") < 0)
          .reduce((acc, p) => [...acc, p.urlMobile], []);
  const [loadedCount, setLoadedCount] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [hide, setHide] = useState(false);

  const handleImageLoad = () => {
    setLoadedCount((prevCount) => {
      const newCount = prevCount + 1;
      setUploadProgress(Math.round((newCount / images.length) * 100));

      if (newCount === images.length) {
        setHide(true);
        setTimeout(() => {
          setUploadProgress(100);
          setUploaded(true);
        }, 500);
      }

      return newCount;
    });
  };

  return (
    <Container hide={hide}>
      <Flex fullW col centerItems>
        <Logo />
        <Parent fullW>
          <ProgressBarContainer>
            <ProgressBar width={uploadProgress}></ProgressBar>

            {images
              .filter((u) => u.indexOf("mp4") < 0)
              .map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt="project"
                  style={{ display: "none" }}
                  onLoad={handleImageLoad}
                />
              ))}
          </ProgressBarContainer>
        </Parent>
      </Flex>
    </Container>
  );
};

const Parent = styled(Flex)`
  max-width: 250px;
  ${Media.tablet`
      max-width: 400px; 
    
    `}
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: fixed;
  background-color: black;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  opacity: ${(props) => (props.hide ? 0 : 1)};
  transition: opacity 0.5s linear;

  ${Media.tablet`
    background-color: white;
  `}
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: black;
  overflow: hidden;
  position: relative;
  height: 15px;
  border: 1px solid white;
  ${Media.tablet`
    background-color: white;
    border: 1px solid black;
  `}
`;

// Barra di caricamento effettiva
const ProgressBar = styled.div`
  width: ${(props) => props.width}%;
  background-color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.3s linear;
  ${Media.tablet`
    background-color: black;
  `}
`;

// Testo all'interno della barra
const ProgressText = styled(Text)`
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
  font-weight: bold;
`;

export default Loader;
