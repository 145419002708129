import React, { Fragment, useEffect, useState } from "react";
import { Container, Desktop } from "./MainContent";
import styled from "styled-components";
import Flex from "./Ui/Flex";
import { TextArrow, TextTitle } from "./ProjectsColumnList";
import Media from "./Styles/Media";
import Text from "./Ui/Text";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

const Project = ({ images, name, services, field, clear, credits }) => {
  const [index, setIndex] = useState(0);

  const navigate = useNavigate();

  const next = () => {
    setIndex((index) => (index + 1) % images.length);
  };
  const prev = () => {
    setIndex((index) => (index - 1 + images.length) % images.length);
  };

  const handlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    onSwipedUp: () => console.log("Swiped up!"),
    onSwipedDown: () => console.log("Swiped down!"),
    preventDefaultTouchmoveEvent: true, // Opzionale, previene lo scroll durante lo swipe
    trackMouse: true, // Opzionale, abilita lo swipe anche con il mouse
  });

  useEffect(() => {
    // Crea un link per precaricare un'immagine
    const link = images.map((image) => {
      const preloadImage = document.createElement("link");
      preloadImage.rel = "preload";
      preloadImage.href = image;
      preloadImage.as = "image";
      document.head.appendChild(preloadImage);
      return preloadImage;
    });

    // Cleanup per rimuovere i link quando il componente si smonta
    return () => {
      link.forEach((l) => document.head.removeChild(l));
    };
  }, [images]);

  return (
    <Container2>
      <External>
        <Flex custom="padding: 16px 10px;" fullW between>
          <Flex centerItems>
            <Text bold>{`${index + 1}/${images.length}`}</Text>
            <Flex custom="margin-left: 10px">
              <Arrow src="/frecce/ar-sx-active.svg" onClick={prev} />
              <Arrow src="/frecce/ar-dx-active.svg" onClick={next} />
            </Flex>
          </Flex>
          <Flex onClick={() => navigate("/work")} pointer>
            <Text bold>CLOSE</Text>
          </Flex>
        </Flex>
      </External>
      <Middle {...handlers}>
        <LeftBox onClick={prev} />
        <RightBox onClick={next} />
        <ImageBox video={images[index]?.indexOf("vimeo") >= 0}>
          {images[index]?.indexOf("vimeo") < 0 ? (
            <Image src={images[index]} alt={name} />
          ) : (
            <Fragment>
              <Desktop>
                <div>
                  <div
                    style={{
                      position: "relative",
                      height: "70vh",
                    }}
                  >
                    <iframe
                      title={name}
                      src={
                        images[index]?.split("___")[1] ??
                        images[index]?.split("___")[0]
                      }
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                    />
                  </div>
                </div>
              </Desktop>

              <Mobile>
                <div
                  style={{
                    position: "relative",
                    height: "70vh",
                  }}
                >
                  <iframe
                    title={name}
                    src={images[index]?.split("___")[0]}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />
                </div>
              </Mobile>
            </Fragment>
          )}
        </ImageBox>
      </Middle>
      <External2>
        <Desktop>
          <Box fullW between endItems>
            <BoxValue col>
              <TextTitle bold>Project</TextTitle>
              <TextArrow2>{name}</TextArrow2>
            </BoxValue>
            <BoxValue col centerItems>
              <TextTitle bold>Role</TextTitle>
              <TextArrow2>{services}</TextArrow2>
            </BoxValue>
            {credits ? (
              <BoxValue
                custom="text-align: right; padding-left: 20px;"
                col
                endItems
              >
                <TextTitle bold>Credits</TextTitle>
                <TextArrow2 dangerouslySetInnerHTML={{ __html: credits }} />
              </BoxValue>
            ) : (
              <BoxValue col endItems>
                <TextTitle bold>Field</TextTitle>
                <TextArrow2>{field}</TextArrow2>
              </BoxValue>
            )}
          </Box>
        </Desktop>
        <Mobile>
          <Box fullW col right>
            <BoxValue between fullW>
              <TextTitle bold>Project</TextTitle>
              <TextArrow2 custom="text-align: right;">{name}</TextArrow2>
            </BoxValue>
            <BoxValue between fullW>
              <TextTitle bold>Services</TextTitle>
              <TextArrow2 custom="text-align: right;">{services}</TextArrow2>
            </BoxValue>
            {credits ? (
              <BoxValue between fullW>
                <TextTitle bold>Credits</TextTitle>
                <TextArrow2
                  custom="text-align: right;"
                  dangerouslySetInnerHTML={{ __html: credits }}
                />
              </BoxValue>
            ) : (
              <BoxValue between fullW>
                <TextTitle bold>Field</TextTitle>
                <TextArrow2>{field}</TextArrow2>
              </BoxValue>
            )}
          </Box>
        </Mobile>
      </External2>
    </Container2>
  );
};

export const Container2 = styled(Container)`
  display: flex;
  flex-direction: column;
`;

const Arrow = styled.div`
  width: 14px;
  height: 14px;
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  &:last-child {
    margin-left: 5px;
  }
`;

export const LeftBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  &:hover {
    cursor: w-resize;
  }
  background-color: transparent;
`;
export const RightBox = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  &:hover {
    cursor: e-resize;
  }
  background-color: transparent;
`;

const Mobile = styled.div`
  width: 100%;
  ${Media.tablet`
    display: none;
  `}
`;
const TextArrow2 = styled(TextArrow)`
  margin-left: 10px;
  ${Media.tablet`margin-left: 0;`}

  &:hover {
    font-style: normal;
  }
`;
const BoxValue = styled(Flex)`
  ${Media.tablet`
  flex: 1;
`}
`;
const Box = styled(Flex)`
  width: 100%;
  height: 100%;
  padding: 2vh;
`;
const External = styled.div`
  display: flex;
  width: 100%;
  height: 8%;
  ${Media.tablet`
    height: 10%;
    `}
`;
const External2 = styled.div`
  display: flex;
  width: 100%;
  height: 12%;
  ${Media.tablet`
    height: 10%;
    `}
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto 0;
  object-fit: contain;
`;

const Middle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 80%;
  ${Media.tablet`
    height: 80%;
  `}
  padding: 10px;
  position: relative;
  &:hover {
    cursor: e-resize;
  }
`;

const ImageBox = styled.div`
  max-width: 100%;
  margin: ${(props) => (props.video ? "" : "0 auto")};
  display: flex;
  height: 100%;
`;

export default Project;
