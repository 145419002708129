import React from "react";
import styled from "styled-components";
import Media from "./Styles/Media";

const FullScreenBackground = ({ background, home }) => {
  const { color, image, video } = background;

  if (video) {
    return (
      <Background color={color} home={home}>
        <Video autoPlay loop muted playsInline>
          <source src={video} type="video/mp4" />
        </Video>
      </Background>
    );
  }

  return <Background color={color} image={image} home={home} />;
};

const Video = styled.video`
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: cover;
  object-position: center;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${(props) => props.color};
  ${(props) => (props.image ? `background-image: url(${props.image});` : "")}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  display: ${(props) => (props.home ? "block" : "none")};
  ${Media.tablet`
    display: block;
  `}
`;

export default FullScreenBackground;
