import React, { useEffect } from "react";
import styled from "styled-components";
import Media from "./Styles/Media";
import Nav from "./Nav";
import { Bottom, Desktop, Top } from "./MainContent";
import ProjectColumnList from "./ProjectsColumnList";
import { projects } from "./projects";

const MainContentProject = ({
  path,
  hover,
  setHover,
  setBackground,
  clear,
}) => {
  useEffect(() => {
    const { desktop } = projects[0];
    setHover(true);
    setBackground({ image: desktop });
  }, [setHover, setBackground]);

  return (
    <Container>
      <Mobile>
        <Top>
          <Nav path={path} hover clear={clear} />
        </Top>
      </Mobile>
      <Desktop>
        <Top></Top>
      </Desktop>
      <Middle></Middle>
      <Desktop>
        <Bottom2>
          <ProjectColumnList
            hover={hover}
            setBackground={setBackground}
            setHover={setHover}
          />
        </Bottom2>
      </Desktop>
    </Container>
  );
};

const Bottom2 = styled(Bottom)`
  height: 100%;
  align-items: flex-end;
`;

export const Mobile = styled.div`
  display: block;
  width: 100%;
  ${Media.tablet`
    visibility: hidden;
  `}
`;
const Container = styled.div`
  width: 100%;
  position: fixed;
  display: block;

  z-index: 1;
  ${Media.tablet`
    z-index: 0;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom:0;
    right:0;
    transform:none;
    display: grid;
    grid-template-rows: 1fr auto 1fr; /* Le righe hanno diverse dimensioni */
  `}
`;

const Middle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 15px;
  z-index: 1;
`;

export default MainContentProject;
