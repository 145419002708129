import React, { useEffect, useState } from "react";

import { home as images } from "./projects";
import { Container2, LeftBox, RightBox } from "./Project";
import styled from "styled-components";
import { useSwipeable } from "react-swipeable";
import { Bottom, Top } from "./MainContent";
import { Mobile } from "./MainContentProject";
import Nav from "./Nav";
import Flex from "./Ui/Flex";
import Text from "./Ui/Text";

const Home = ({ setBackground, setNewHover, path, clear, hover }) => {
  const [index, setIndex] = useState(0);
  const { footerWhite } = images[0];

  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    const { navWhite, url, urlMobile, video } = images[0];
    const urlToUse = isMobile ? urlMobile : url;
    setBackground(video ? { video: urlToUse } : { image: urlToUse });
    if (setNewHover) {
      if (navWhite) {
        setNewHover(true);
      } else {
        setNewHover(false);
      }
    }
  }, [setNewHover, setBackground]);

  const next = () => {
    setIndex((index) => (index + 1) % images.length);
    const { navWhite, url, video, urlMobile } =
      images[(index + 1) % images.length];
    const isMobile = window.innerWidth < 768;
    const urlToUse = isMobile ? urlMobile : url;
    setBackground(video ? { video: urlToUse } : { image: urlToUse });
    if (navWhite) {
      setNewHover(true);
    } else {
      setNewHover(false);
    }
  };
  const prev = () => {
    setIndex((index) => (index - 1 + images.length) % images.length);
    const { navWhite, url, video, urlMobile } =
      images[(index - 1 + images.length) % images.length];
    const isMobile = window.innerWidth < 768;
    const urlToUse = isMobile ? urlMobile : url;
    setBackground(video ? { video: urlToUse } : { image: urlToUse });
    if (navWhite) {
      setNewHover(true);
    } else {
      setNewHover(false);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    onSwipedUp: () => console.log("Swiped up!"),
    onSwipedDown: () => console.log("Swiped down!"),
    preventDefaultTouchmoveEvent: true, // Opzionale, previene lo scroll durante lo swipe
    trackMouse: true, // Opzionale, abilita lo swipe anche con il mouse
  });
  return (
    <Container2>
      <Top>
        <Mobile>
          <Nav path={path} clear={clear} hover={hover} />
        </Mobile>
      </Top>
      <Middle {...handlers}>
        <LeftBox onClick={prev} />
        <RightBox onClick={next} />
      </Middle>
      <Bottom>
        <Flex
          between
          centerItems
          custom="max-width: 490px; margin: 0 auto; width: 100%;"
        >
          <Flex
            col
            custom="display: grid; grid-template-rows: 1fr 1f 1fr;"
          ></Flex>
          <Flex col endItems>
            <a href="mailto:hello@sensai.to">
              <Text white={footerWhite} bold custom="text-align: right;">
                HELLO@PAOLAIOTTI.COM
              </Text>
            </a>
            <a href="https://www.instagram.com/paolinaiotti/">
              <Text white={footerWhite} bold custom="text-align: right;">
                @PAOLAIOTTI
              </Text>
            </a>
          </Flex>
        </Flex>
      </Bottom>
    </Container2>
  );
};

const Middle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  position: relative;
  &:hover {
    cursor: e-resize;
  }
`;

export default Home;
