import React from "react";
import styled from "styled-components";
import Media from "./Styles/Media";

const Logo = ({ hover }) => {
  return <Svg hover={hover} />;
};

const Svg = styled.div`
  background-image: url("/logo/logo_paolaiotti_bianco.svg");
  width: 100px;
  height: 20px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;

  ${Media.phone`
    height: 20px;
  `}
  ${Media.largePhone`
    height: 20px;
  `}

  ${Media.tablet`
    height: 20px;
    background-image: url(${(props) =>
      props.hover
        ? "/logo/logo_paolaiotti_nero.svg"
        : "/logo/logo_paolaiotti_bianco.svg"});
  `}
`;

export default Logo;
